<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>壁纸列表</div>
                </div>

                <div class="admin_main_block_right">
                    <div>
                        <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                    </div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form label-width="100px" ref="info" :model="info">
                    <el-form-item label="分类" prop="F_IN_FENLID"
                                  :rules="[{required:false,message:'分类不能为空',trigger: 'blur' }]">
                        <el-select style="width:300px;" v-model="info.F_IN_FENLID" placeholder="请选择分类"
                                   @change="selectChange()">
                            <el-option label="请选择分类" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['fenl']" :label="v.F_VR_FENLMC" :key="k"
                                       :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <!--<el-form-item label="标签" prop="F_VR_PEIJ" >
                        <el-select style="width:320px"  v-model="xxx" multiple value-key="F_IN_ID" collapse-tags  placeholder="请选择" @change="change">
                            <el-option
                                    v-for="(item,index) in list['biaoq']"
                                    :key="index"
                                    :label="item.F_VR_BIAOQMC"
                                    :value="item.F_VR_BIAOQMC">
                            </el-option>
                        </el-select>
                    </el-form-item>-->

                    <el-form-item label="标签" style="margin-left:60px;" label-width="120px;" prop="F_VR_BIAOQID">
                        <el-select style="width:300px" v-model="info.F_VR_BIAOQID" multiple value-key="F_IN_ID"
                                   placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in list['biaoq']"
                                    :key="index"
                                    :label="item.F_VR_BIAOQMC"
                                    :value="item.F_VR_BIAOQMC">
                            </el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="主题" prop="F_VR_ZHUT"
                                  :rules="[{required:true,message:'主题不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入主题" v-model="info.F_VR_ZHUT"></el-input>
                    </el-form-item>

                    <el-form-item label="壁纸" prop="thumb">
                        <el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers"
                                   :show-file-list="false" :on-success="handleAvatarSuccess">
                            <img v-if="info.F_VR_TUP" :src="info.F_VR_TUP" class="avatar-upload">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="热门推荐" prop="F_TI_REMTJ">
                        <el-radio-group v-model="info.F_TI_REMTJ">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="官方推荐" prop="F_TI_GUANFTJ">
                        <el-radio-group v-model="info.F_TI_GUANFTJ">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="上下架" prop="F_TI_SHANGXJ">
                        <el-radio-group v-model="info.F_TI_SHANGXJ">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <el-button @click="resetForm('info')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                edit_id: 0,
                info: {
                    F_TI_REMTJ:1,
                    F_TI_GUANFTJ:1,
                    F_TI_SHANGXJ:1,

                },
                roles_list: [],
                roles: [],
                upload_headers: {},
                list: [],
            };
        },
        watch: {},
        computed: {},
        methods: {
            resetForm: function (e) {
                this.$refs[e].resetFields();
            },
            submitForm: function (e) {
                let _this = this;

                // 验证表单
                this.$refs[e].validate(function (res) {
                    if (res) {
                        //  判断是Add 或者 Edit
                        let url = _this.$api.addTgBizlb;
                        if (_this.edit_id > 0) {
                            url = _this.$api.editTgBizlb + _this.edit_id;
                        }
                        // Http 请求
                        _this.$post(url, _this.info).then(function (res) {
                            if (res.code == 200) {
                                _this.$message.success("编辑成功");
                                _this.$router.go(-1);
                            } else {
                                _this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            get_users_info: function () {
                let _this = this;
                this.$get(this.$api.editTgBizlb + this.edit_id).then(function (res) {
                    _this.info = res.data['info'];
                    _this.list['fenl'] = res.data['fenl'];
                    _this.list['biaoq'] = res.data['biaoq'];
                })
            },
            get_adv_list: function () {
                let _this = this;
                this.$get(this.$api.addTgBizlb).then(function (res) {
                    _this.list = res.data;
                })

            },
            handleAvatarSuccess(res) {
                this.info.F_VR_TUP = res.data;
                this.$forceUpdate();
            },
        },
        created() {
            this.upload_headers.Authorization = 'Bearer ' + localStorage.getItem('token'); // 要保证取到
            // 判断是否是编辑
            if (!this.$isEmpty(this.$route.params.id)) {
                this.edit_id = this.$route.params.id;
            }
            if (this.edit_id > 0) {
                this.get_users_info();
            } else {
                this.get_adv_list();
            }
        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>

</style>